/*
 * Copyright 2015, Yahoo Inc.
 * Copyrights licensed under the New BSD License.
 * See the accompanying LICENSE file for terms.
 */

export default function defineMessages(messageDescriptors) {
    // This simply returns what's passed-in because it's meant to be a hook for
    // babel-plugin-react-intl.
    return messageDescriptors;
}
